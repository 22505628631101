import styled from 'styled-components';
import { color, mediaBreakpointUp } from '@/style/mixins';

export const Container = styled.div`
    position: relative;
    opacity: 1;
    visibility: visible;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: ${color('white')};
    pointer-events: none;
    padding: 30px 20px 60px 20px;

    .side {
        width: 98px;
        height: 20px;
        opacity: 0.1;
        border-radius: 8px;
        background-color: ${color('grayish')};

        ${mediaBreakpointUp('xl')} {
            position: absolute;
            right: 0;
            top: 343px;
            width: 78px;
            height: 182px;
        }
    }

    main {
        margin-top: 66px;

        ${mediaBreakpointUp('xl')} {
            display: none;
        }
        div {
            width: 100%;
            height: 20px;
            opacity: 0.1;
            border-radius: 8px;
            background-color: ${color('grayish')};
            margin-bottom: 16px;

            ${mediaBreakpointUp('md')} {
                width: 218px;

                &:nth-child(3) {
                    width: 293px;
                }

                &:nth-child(4) {
                    width: 341px;
                }
            }

            &.short {
                width: 206px;
                margin-bottom: 32px;
            }
        }
    }

    ${mediaBreakpointUp('md')} {
        padding: 40px 00px 60px 30px;
    }

    &.fadeContainer-enter {
        opacity: 1;
        visibility: visible;
    }

    &.fadeContainer-exit {
        opacity: 1;
        visibility: visible;
    }
    &.fadeContainer-exit-active {
        opacity: 0;
        visibility: hidden;
        transition: opacity 1.2s 0.8s cubic-bezier(0.425, 0.005, 0, 1), visibility 1.2s 0.8s cubic-bezier(0.425, 0.005, 0, 1);
    }
    &.fadeContainer-exit-done {
        opacity: 0;
        visibility: hidden;
    }
`;

export const Head = styled.div`
    position: relative;
    display: flex;
    margin-right: 20px;
    min-height: 40px;
    border-bottom: 1px solid ${color('grayish', 0.2)};

    ${mediaBreakpointUp('md')} {
        margin-right: 40px;
        min-height: 50px;
    }

    div {
        border-radius: 4px;
        opacity: 0.15;
        background-color: ${color('grayish')};
        height: 15px;

        ${mediaBreakpointUp('fhd')} {
            height: 21px;
        }
    }

    .longdiv {
        width: 183px;
    }

    .links {
        display: none;
        ${mediaBreakpointUp('xl')} {
            margin-left: 20%;
            display: flex;
        }

        ${mediaBreakpointUp('fhd')} {
            margin-left: 27.5%;
        }
    }

    .shortdiv {
        width: 98px;
        margin-left: 24px;
        margin-right: 24px;

        ${mediaBreakpointUp('fhd')} {
            margin-left: 40px;
            margin-right: 40px;
        }

        &:last-child {
            margin-left: 0;
        }
    }
    .login {
        display: none;

        @media (min-width: 1280px) {
            position: absolute;
            top: 0;
            right: 0;
            width: 87px;
            display: block;
        }
    }

    .lang {
        position: absolute;
        right: 0;
        top: 0;
        width: 27px;
        height: 20px;

        ${mediaBreakpointUp('md')} {
            height: 25px;
            width: 25px;
        }

        ${mediaBreakpointUp('xl')} {
            margin-left: 5%;
            position: relative;
            width: 18px;
            height: 15px;
        }

        ${mediaBreakpointUp('xxl')} {
            margin-left: 14%;
        }

        ${mediaBreakpointUp('fhd')} {
            margin-left: 18%;
            width: 27px;
            height: 21px;
        }
    }

    svg {
        width: 137px;
        height: 21px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.4;
        fill: ${color('black')};

        ${mediaBreakpointUp('md')} {
            width: 174px;
            height: 26px;
        }

        ${mediaBreakpointUp('xl')} {
            width: 142px;
            height: 22px;
        }

        ${mediaBreakpointUp('fhd')} {
            width: 214px;
            height: 33px;
        }
    }
`;
export const Footer = styled.section`
    position: absolute;
    bottom: 60px;
    left: 20px;
    right: 20px;
    width: auto;

    ${mediaBreakpointUp('fhd')} {
        bottom: 80px;
        left: 80px;
    }

    .widgets {
        display: flex;
    }

    .line {
        width: 194px;
        height: 20px;
        margin-bottom: 8px;
        border-radius: 8px;
        background-color: ${color('grayish')};
        opacity: 0.1;

        ${mediaBreakpointUp('xl')} {
            display: none;
        }
    }

    .search {
        display: flex;
        height: auto;
        width: auto;
        margin-bottom: 32px;

        ${mediaBreakpointUp('xl')} {
            display: none;
        }
    }

    .input {
        width: 283px;
        height: 44px;
        margin-right: 8px;

        ${mediaBreakpointUp('md')} {
            width: 636px;
        }
    }

    .button {
        width: 44px;
        height: 44px;
    }

    span {
        border-radius: 8px;
        opacity: 0.1;
        background-color: ${color('grayish')};
        height: 120px;
        width: 106px;
        margin-right: 8px;

        ${mediaBreakpointUp('fhd')} {
            height: 251px;
            width: 214px;

            &:first-child {
                width: 952px;
            }
        }
    }

    .square {
        &:first-child {
            display: none;
        }

        border-radius: 8px;
        opacity: 0.1;
        background-color: ${color('grayish')};
        height: 120px;
        width: 106px;
        margin-right: 8px;

        ${mediaBreakpointUp('md')} {
            width: 224px;
        }

        ${mediaBreakpointUp('xl')} {
            width: 138px;
            height: 185px;

            &:first-child {
                display: block;
                width: 735px;
            }
        }

        ${mediaBreakpointUp('fhd')} {
            height: 251px;
            width: 214px;

            &:first-child {
                width: 952px;
            }
        }
    }
`;

export const Main2 = styled.footer`
    ${mediaBreakpointUp('xl')} {
        display: block;
        margin-left: 349px;
    }

    ${mediaBreakpointUp('fhd')} {
        margin-left: 522px;
    }
    span {
        background-color: ${color('grayish')};
        opacity: 0.1;
    }
    .row {
        display: flex;
        justify-content: space-between;
        margin-top: 50vh;

        ${mediaBreakpointUp('md')} {
            margin-top: 600px;
        }

        ${mediaBreakpointUp('xl')} {
            margin-top: 50vh;
        }

        ${mediaBreakpointUp('xxl')} {
            margin-top: 55vh;
        }

        ${mediaBreakpointUp('fhd')} {
            margin-top: 58vh;
        }
    }

    .block {
        width: 334px;
        height: 25vh;
        position: absolute;
        bottom: 0;
        background-color: ${color('grayish')};
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        ${mediaBreakpointUp('md')} {
            width: 688px;
            height: 230px;
        }

        ${mediaBreakpointUp('xl')} {
            position: relative;
            display: block;
            width: 878px;
            height: 100vh;
            margin-top: 16px;
        }

        ${mediaBreakpointUp('xxl')} {
            width: 989px;
            margin-top: 24px;
        }

        ${mediaBreakpointUp('fhd')} {
            width: 1318px;
            margin-top: 32px;
        }
    }

    .element {
        width: 103px;
        height: 32px;

        ${mediaBreakpointUp('xl')} {
            width: 212px;
        }

        ${mediaBreakpointUp('xxl')} {
            width: 306px;
            height: 24px;
        }

        ${mediaBreakpointUp('fhd')} {
            width: 422px;
        }
    }

    .element2 {
        width: 77px;
        height: 16px;

        ${mediaBreakpointUp('md')} {
            margin-right: 40px;
            width: 81px;
            height: 16px;
        }

        ${mediaBreakpointUp('xl')} {
            display: none;
        }
    }

    nav {
        display: none;

        ${mediaBreakpointUp('xl')} {
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 54px;
            bottom: 20px;
        }

        ${mediaBreakpointUp('qhd')} {
            bottom: 200px;
        }

        .logo {
            width: 140px;
            height: 44px;
            margin-bottom: 16px;

            ${mediaBreakpointUp('fhd')} {
                width: 186px;
                height: 58px;
            }
        }

        .description {
            width: 212px;
            height: 20px;
            margin-bottom: 64px;

            ${mediaBreakpointUp('fhd')} {
                margin-bottom: 85px;
                width: 280px;
            }
        }

        .lines {
            width: 212px;
            height: 20px;
            margin-bottom: 16px;

            &:nth-child(4) {
                display: none;
            }

            ${mediaBreakpointUp('xxl')} {
                &:nth-child(4) {
                    display: block;
                }
            }

            ${mediaBreakpointUp('fhd')} {
                height: 24px;
                width: 280px;
            }
        }
    }
`;

export const SideBar = styled.nav`
    width: 400px;
    height: 100vh;
    position: relative;
    display: none;

    ${mediaBreakpointUp('xl')} {
        display: block;
        width: 295px;
    }

    span {
        background-color: ${color('grayish')};
        opacity: 0.15;
        border-radius: 4px;
        display: block;
        margin-left: 40px;
    }

    .logo {
        margin-top: 32px;
        width: 140px;
        height: 44px;

        ${mediaBreakpointUp('fhd')} {
            width: 186px;
            height: 58px;
            margin-top: 77px;
        }
    }

    .description {
        width: 280px;
        height: 24px;
        margin-top: 28px;
        margin-bottom: 85px;

        &2 {
            width: 280px;
            height: 24px;
        }

        ${mediaBreakpointUp('xl')} {
            margin-top: 16px;
            width: 212px;
            height: 20px;

            &2 {
                width: 212px;
                height: 20px;
            }
        }
    }

    .lines {
        width: 140px;
        height: 20px;
        margin-top: 16px;

        ${mediaBreakpointUp('fhd')} {
            width: 186px;
            height: 24px;
            margin-top: 16px;
        }
    }

    .banner {
        margin-top: 40px;
        width: 213px;
        height: 104px;

        ${mediaBreakpointUp('fhd')} {
            margin-top: 44px;
            width: 320px;
            height: 158px;
        }
    }
`;

export const Body = styled.div`
    margin-top: 19px;
    width: 100%;

    ${mediaBreakpointUp('xl')} {
        margin-top: 34px;
    }

    ${mediaBreakpointUp('xxl')} {
        margin-top: 32px;
    }

    ${mediaBreakpointUp('fhd')} {
        margin-top: 77px;
    }

    span {
        background-color: ${color('grayish')};
        opacity: 0.15;
        border-radius: 4px;
        display: block;
    }

    .row {
        display: flex;
        width: 95%;
        padding-right: 40px;
        align-items: center;

        ${mediaBreakpointUp('md')} {
            width: 100%;
        }

        ${mediaBreakpointUp('xl')} {
            margin-left: 54px;
        }

        ${mediaBreakpointUp('xxl')} {
            margin-left: 122px;
        }
    }

    .line {
        width: 103px;
        height: 32px;
        margin-right: auto;

        &:not(:first-child) {
            display: none;
        }

        ${mediaBreakpointUp('xl')} {
            width: 125px;
            height: 15px;
            margin-right: 12px;
            &:not(:first-child) {
                display: block;
            }
        }

        ${mediaBreakpointUp('fhd')} {
            width: 125px;
            height: 15px;
            margin-right: 12px;
        }
    }

    .mbLine {
        width: 77px;
        height: 16px;

        ${mediaBreakpointUp('xl')} {
            display: none;
        }
    }

    .boxLine {
        display: flex;
        flex-direction: column;

        margin-top: 40px;
        width: 86vw;

        justify-content: space-between;

        ${mediaBreakpointUp('md')} {
            flex-direction: row;
            padding-right: 40px;
            align-items: center;
            width: 100%;
        }

        ${mediaBreakpointUp('xl')} {
            margin-left: 54px;
            margin-top: 27px;
        }

        ${mediaBreakpointUp('xxl')} {
            margin-left: 122px;
            margin-top: 20px;
        }

        ${mediaBreakpointUp('fhd')} {
            margin-top: 27px;
        }
    }

    .bigLine {
        width: 222px;
        height: 28px;

        ${mediaBreakpointUp('md')} {
            width: 410px;
            height: 26px;
        }

        ${mediaBreakpointUp('xl')} {
            border-radius: 8px;
        }

        ${mediaBreakpointUp('xxl')} {
            width: 487px;
            height: 36px;
        }

        ${mediaBreakpointUp('fhd')} {
            width: 419px;
            height: 36px;
        }
    }

    .mbBigLine {
        width: 197px;
        height: 28px;
        margin-top: 8px;

        ${mediaBreakpointUp('md')} {
            margin-top: 0;
            width: 175px;
            height: 26px;
        }

        ${mediaBreakpointUp('xl')} {
            display: none;
        }
    }

    .body {
        width: 100vw;
        height: 60vh;
        margin-top: 20px;
        position: absolute;
        bottom: 0;
        left: 0;

        ${mediaBreakpointUp('md')} {
            height: 788px;
            margin-top: 24px;
        }

        ${mediaBreakpointUp('xl')} {
            position: relative;
            margin-top: 32px;
            right: 0;
            width: 71vw;
            margin-left: 54px;
            height: 100vh;
        }

        @media (min-width: 1280px) and (max-width: 1439px) {
            width: 76vw;
        }

        ${mediaBreakpointUp('xxl')} {
            margin-top: 39px;
            width: 76vw;
            margin-left: 122px;
        }

        ${mediaBreakpointUp('fhd')} {
            width: 1478px;
            margin-top: 76px;
        }

        ${mediaBreakpointUp('qhd')} {
            width: 2108px;
        }
    }
`;

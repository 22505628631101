import styled from 'styled-components';
import { color } from '@/style/mixins';

export const Container = styled.div<{ showed?: boolean; leave?: boolean }>`
    position: fixed;
    background-color: ${color('white')};
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900;

    visibility: ${({ showed }) => (showed ? 'visible' : 'hidden')};
    opacity: ${({ showed }) => (showed ? '1' : '0')};

    transition: visibility ${({ showed }) => (showed ? '0.5s cubic-bezier(.165,.84,.44,1)' : '0.5s 0.1s cubic-bezier(.165,.84,.44,1)')},
        opacity ${({ showed }) => (showed ? '0.5s cubic-bezier(.165,.84,.44,1)' : '0.5s 0.1s cubic-bezier(.165,.84,.44,1)')};
`;

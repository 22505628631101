import React from 'react';
import { Body, Container, Footer, Head, Main2, SideBar } from './Skeletons.styled';
import { setDone } from '@/redux/pageTransition/pageTransitionSlice';
import { connect, ConnectedProps } from 'react-redux';
import Logo from '@/icons/logo.svg';
import LogoEn from '@/icons/logo-en.svg';
import { useRouter } from 'next/router';

const mapDispatchToProps = { setDone };
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type PageTransitionType = PropsFromRedux & {
    variant: 'main' | 'nohead' | 'default';
};

const PageTransition: React.FC<PageTransitionType> = ({ variant }) => {
    const { locale } = useRouter();
    return (
        <Container>
            <Head>
                {locale === 'ru' ? <Logo /> : <LogoEn />}
                <section className="links">
                    <div className="longdiv"></div>
                    <div className="shortdiv"></div>
                    <div className="longdiv"></div>
                    <div className="shortdiv"></div>
                    <div className="shortdiv"></div>
                </section>
                <div className="lang"></div>
                <div className="login"></div>
            </Head>
            {variant === 'main' ? (
                <>
                    <main>
                        <div></div>
                        <div className="short"></div>
                        <div></div>
                        <div></div>
                    </main>
                    <div className="side"></div>
                    <Footer>
                        <div className="line"></div>
                        <div className="search">
                            <span className="input"></span>
                            <span className="button"></span>
                        </div>
                        <section className="widgets">
                            <span className="square"></span>
                            <span className="square"></span>
                            <span className="square"></span>
                            <span className="square"></span>
                        </section>
                    </Footer>
                </>
            ) : variant === 'default' ? (
                <Main2>
                    <nav>
                        <span className="logo"></span>
                        <span className="description"></span>
                        <span className="lines"></span>
                        <span className="lines"></span>
                        <span className="lines"></span>
                        <span className="lines"></span>
                    </nav>
                    <section className="row">
                        <span className="element"></span>
                        <span className="element2"></span>
                    </section>
                    <span className="block"></span>
                </Main2>
            ) : (
                <div style={{ display: 'flex' }}>
                    <SideBar>
                        <span className="logo"></span>
                        <span className="description"></span>
                        <span className="description2"></span>
                        <span className="lines"></span>
                        <span className="lines"></span>
                        <span className="lines"></span>
                        <span className="banner"></span>
                    </SideBar>
                    <Body>
                        <div className="row">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="mbLine"></span>
                        </div>
                        <div className="boxLine">
                            <span className="bigLine"></span>
                            <span className="mbBigLine"></span>
                        </div>
                        <span className="body"></span>
                    </Body>
                </div>
            )}
        </Container>
    );
};

export default connector(PageTransition);
